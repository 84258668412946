export function initMobileNav() {
  const hamburgerIcon = document.querySelector(".nav__button-hamburger--js");
  const closeIcon = document.querySelector(".nav__button-close--js");
  const navLinks = document.querySelector(".nav__links--js");

  if (!hamburgerIcon || !closeIcon || !navLinks) {
    console.error("Navigation elements not found");
    return;
  }

  const toggleNav = () => {
    hamburgerIcon.classList.toggle("hidden");
    closeIcon.classList.toggle("hidden");
    navLinks.classList.toggle("open-nav-overlay");
  };

  hamburgerIcon.addEventListener("click", toggleNav);
  closeIcon.addEventListener("click", toggleNav);
}
