export function setupProtectedLinks(links) {
  links.forEach((link) => {
    link.addEventListener("click", handleProtectedLink);
  });
}

export function authenticate(event) {
  event.preventDefault();
  const passwordField = document.getElementById("password-form__input--js");
  const errorMessage = document.getElementById(
    "password-form__error-message--js",
  );

  let passwordInput = passwordField.value;

  if (passwordInput === "bubbles") {
    let redirectUrl = sessionStorage.getItem("redirectUrl");
    errorMessage.classList.add("hidden");
    sessionStorage.setItem("authenticated", "true");
    window.location.assign(redirectUrl);
  } else {
    errorMessage.classList.remove("hidden");
  }
}

export function setupPasswordForm(passwordForm) {
  passwordForm.addEventListener("submit", authenticate);
}

function handleProtectedLink(event) {
  if (sessionStorage.getItem("authenticated") !== "true") {
    event.preventDefault();
    let redirectUrl = event.currentTarget.getAttribute("href");
    sessionStorage.setItem("redirectUrl", redirectUrl);
    window.location.assign("/protected.html");
  }
}
