export function initCarousel() {
  const carouselSlide = document.querySelector(".carousel__slide--js");
  const carouselImages = document.querySelectorAll(".carousel__slide--js img");

  //Buttons
  const prevBtn = document.querySelector(".prev-btn--js");
  const nextBtn = document.querySelector(".next-btn--js");

  if (!carouselImages || !carouselSlide || !prevBtn || !nextBtn) {
    console.error("Carousel elements not found");
    return;
  }

  //Initialize variables
  let counter = 1;
  let size = carouselImages[0].clientWidth;

  moveCarousel();

  window.addEventListener("resize", handleResize);
  nextBtn.addEventListener("click", moveToNextSlide);
  prevBtn.addEventListener("click", moveToPrevSlide);
  carouselSlide.addEventListener("transitionend", resetCarousel);

  function moveCarousel() {
    carouselSlide.style.transform = "translateX(" + -size * counter + "px)";
  }

  function styleCarouselTransition() {
    carouselSlide.style.transition = "transform 0.4s ease-in-out";
  }

  function handleResize() {
    size = carouselImages[0].clientWidth;
    carouselSlide.style.transition = "none";
    moveCarousel();
  }

  function moveToNextSlide() {
    if (counter >= carouselImages.length - 1) return;
    counter++;
    styleCarouselTransition();
    moveCarousel();
  }

  function moveToPrevSlide() {
    if (counter <= 0) return;
    counter--;
    styleCarouselTransition();
    moveCarousel();
  }

  //Checks for clone - beginning or end of carousel.
  function resetCarousel() {
    if (carouselImages[counter].classList.contains("last-clone--js")) {
      counter = carouselImages.length - 2;
      carouselSlide.style.transition = "none";
      moveCarousel();
    }

    if (carouselImages[counter].classList.contains("first-clone--js")) {
      counter = carouselImages.length - counter;
      carouselSlide.style.transition = "none";
      moveCarousel();
    }
  }
}
