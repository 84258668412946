import { initMobileNav } from "./navigation";
import { initCarousel } from "./carousel";
import { setupPasswordForm, setupProtectedLinks } from "./authHandlers";

document.addEventListener("DOMContentLoaded", () => {
  initMobileNav();

  const carouselElement = document.querySelector(".carousel__slide--js");
  if (carouselElement) {
    initCarousel();
  }

  const links = document.querySelectorAll("a[data-protected]");
  if (links) {
    setupProtectedLinks(links);
  }

  const passwordForm = document.getElementById("password-form--js");
  if (passwordForm) {
    setupPasswordForm(passwordForm);
  }
});
